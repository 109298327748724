.btn {
    padding: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    font-weight: 500;
    font-size: 17px;
    border-radius: 12px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;

    &:disabled {
        background: #7676801F;
        color: #3C3C434D;
        cursor: not-allowed;
    }
}

.primary-btn {
    background: linear-gradient(to right, #4A55BA, #6271FF);
    color: #FFF;
}

.secondary-btn {
    background: #E0E3FF;
    color: #6271FF;
}

.outlined-btn {
    background: #FFF;
    color: #6271FF;
    border: 1px solid #6271FF;
}

.btn-icon {
    display: inline-flex;
    align-items: center;
}

.btn-title {
    display: inline-flex;
    align-items: center;
}
